import Immutable from '@core-ui/immutable';
import reducer from '@core-ui/reducers_handlers';
import { IOption, Nullable } from '@core-ui/types';
import { getEndOfTodayInUnix, getMonthAgoInUnix } from '@/app/utils/dates';
import { FileStatusEnum } from '@/generated';
import { handleActions } from 'redux-actions';
import * as actions from './actions';

export interface IInboxFiltersState {
  dateFrom: Nullable<string>;
  dateTo: Nullable<string>;
  uploadedBy: Nullable<IOption<string, string>[]>;
  status: Nullable<IOption<FileStatusEnum, FileStatusEnum>[]>;
}

const defaultState = Immutable<IInboxFiltersState>({
  dateFrom: String(getMonthAgoInUnix()),
  dateTo: String(getEndOfTodayInUnix()),
  uploadedBy: null,
  status: null,
});

export default handleActions({}, reducer<typeof defaultState>(actions.reducerName, defaultState));
