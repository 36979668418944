import State from '@/app/types/state';
import { toMutableArray } from '@/app/utils/app';
import { getUploadedByOptions } from '@/dictionary/selector';
import { createSelector } from 'reselect';

export const getSingleContractTransactionsFilters = (state: State) =>
  state.backoffice.contracts.singleContractTransactions.filters;

export default createSelector(
  [getSingleContractTransactionsFilters, getUploadedByOptions],
  (filters, uploadedByOptions) => ({
    filters: {
      ...filters,

      uploadedBy: toMutableArray(filters.uploadedBy),
      status: toMutableArray(filters.status),
    },
    uploadedByOptions,
  })
);
