import { FileStatusEnum } from '@/generated';
import { createAction } from 'redux-actions';

export const reducerName = 'contracts.single_contract.row';

export interface IUpdateErpCategory {
  documentId: number;
  categoryId: string;
}

export const updateErpCategory = createAction<IUpdateErpCategory>(`${reducerName}.UPDATE_ERP_CATEGORY`);

export interface IDeleteSingleContractDocument {
  id: number;
  status: FileStatusEnum;
}

export const deleteSingleContractDocument = createAction<IDeleteSingleContractDocument>(
  `${reducerName}.DELETE_DOCUMENT`
);
