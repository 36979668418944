import State from '@/app/types/state';
import { toMutableArray } from '@/app/utils/app';
import { getUploadedByOptions } from '@/dictionary/selector';
import { createSelector } from 'reselect';

export const getInboxFilters = (state: State) => state.backoffice.inbox.filters;

export default createSelector([getInboxFilters, getUploadedByOptions], (filters, uploadedByOptions) => ({
  filters: {
    ...filters,

    status: toMutableArray(filters.status),
    uploadedBy: toMutableArray(filters.uploadedBy),
  },
  uploadedByOptions,
}));
