import { getData, setData } from '@core-ui/reducers_handlers';
import { resetState } from '@core-ui/reducers_handlers/actions';
import { ContractOutputSchema } from '@/generated';

export const reducerName = 'contracts.single_contract';

export const getSingleContract = getData(reducerName);
export const setSingleContract = setData<ContractOutputSchema>(reducerName);

export const resetSingleContract = resetState(reducerName);
