import reducer, { List } from '@core-ui/reducers_handlers';
import { Nullable } from '@core-ui/types';
import { ContractOutputSchema, OrderContractsFields } from '@/generated';
import { handleActions } from 'redux-actions';
import Immutable from '@core-ui/immutable';
import * as actions from './actions';

export interface IContractsListState extends List<ContractOutputSchema> {
  orderBy: Nullable<OrderContractsFields>;
  isSortDesc: Nullable<boolean>;
  paginationPage: number;
}

const defaultState = Immutable<IContractsListState>({
  loaded: false,
  hasData: false,
  loading: false,
  chunkLoading: false,
  isAllDataReceived: false,
  orderBy: OrderContractsFields.EXPIRES_AT,
  isSortDesc: true,
  paginationPage: 0,
});

export default handleActions<typeof defaultState, unknown>(
  {
    [actions.setOrderBy.toString()]: (state, { payload }) => {
      return state.set('orderBy', payload);
    },
    [actions.setSortDesc.toString()]: (state, { payload }) => {
      return state.set('isSortDesc', payload);
    },
    [actions.setPaginationPage.toString()]: (state, { payload }) => {
      return state.set('paginationPage', payload);
    },
  },
  reducer<typeof defaultState>(actions.reducerName, defaultState)
);
