import Tooltip from '@mui/material/Tooltip';
import { myocean__routes__inbox__inbox_list__OrderFields } from '@/generated';
import { ITableColumn } from '@/pages/backoffice/types';
import React from 'react';
import { FormattedMessage } from 'components/Intl';

// TODO: отсутствует колонка Tags т.к. её реализация отложена и на фронте и на бэке
export const HEADERS: ITableColumn[] = [
  {
    key: 'document_name',
    title: <FormattedMessage id="table.header.document_name" />,
    orderBy: myocean__routes__inbox__inbox_list__OrderFields.FILENAME,
    dataTestId: 'table-column-head-filename',
    width: 300,
  },
  {
    key: 'vendor',
    title: <FormattedMessage id="table.header.vendor" />,
    orderBy: myocean__routes__inbox__inbox_list__OrderFields.VENDOR_NAME,
    dataTestId: 'table-column-head-vendor',
    width: 200,
  },
  {
    key: 'upload_date',
    title: <FormattedMessage id="table.header.upload_date" />,
    orderBy: myocean__routes__inbox__inbox_list__OrderFields.CREATED_AT,
    dataTestId: 'table-column-head-created-at',
    width: 130,
  },
  {
    key: 'document_status',
    title: <FormattedMessage id="table.header.doc_status" />,
    orderBy: myocean__routes__inbox__inbox_list__OrderFields.DOC_STATUS,
    dataTestId: 'table-column-head-status',
    width: 190,
  },
  {
    key: 'uploaded_by',
    title: <FormattedMessage id="label.uploaded_by" />,
    orderBy: myocean__routes__inbox__inbox_list__OrderFields.UPLOADED_BY_ID,
    dataTestId: 'table-column-head-uploaded-by',
    width: 200,
  },
  {
    key: 'currency',
    title: (
      <Tooltip title={<FormattedMessage id="table.header.currency" />} placement="top" arrow>
        <div>
          <FormattedMessage id="table.header.currency_short" />
        </div>
      </Tooltip>
    ),
    width: 54,
    align: 'center',
  },
  {
    key: 'expenses',
    title: <FormattedMessage id="table.header.expenses" />,
    width: 120,
    align: 'right',
  },
  {
    title: '',
    key: 'actions',
    width: 108,
  },
];
