import dragNDrop, {
  ISingleContractTransactionsDragNDropState,
} from '@/pages/backoffice/Contracts/SingleContractTransactions/SingleContractTransactionsDragNDrop/reducer';
import list, {
  ISingleContractTransactionsListState,
} from '@/pages/backoffice/Contracts/SingleContractTransactions/SingleContractTransactionsList/reducer';
import filters, {
  ISingleContractListFiltersState,
} from '@/pages/backoffice/Contracts/SingleContractTransactions/SingleContractTransactionsFilters/reducer';
import { combineReducers } from 'redux';

export interface ISingleContractTransactions {
  dragNDrop: ISingleContractTransactionsDragNDropState;
  filters: ISingleContractListFiltersState;
  list: ISingleContractTransactionsListState;
}

export default combineReducers({
  dragNDrop,
  filters,
  list,
});
