import { COLOURS } from '@core-ui/styles';
import { Nullable } from '@core-ui/types';
import { IMenuOption } from '@/components/Menu/Menu';
import MoreButton from '@/components/MoreButton';
import { ContractOutputSchema } from '@/generated';
import { openBackofficeModal } from '@/pages/backoffice/Modals/actions';
import { CreateEditContractModalData, DeleteContractModalData } from '@/pages/backoffice/Modals/command';
import { NotePencil, Trash } from '@phosphor-icons/react';
import React, { FC } from 'react';
import { FormattedMessage } from 'components/Intl';
import { useDispatch } from 'react-redux';

interface IProps {
  item: Nullable<ContractOutputSchema>;
}

const SingleContractHeaderMoreButton: FC<IProps> = (props) => {
  const { item } = props;

  const dispatch = useDispatch();

  const handleEdit = () => {
    if (item) {
      dispatch(openBackofficeModal(new CreateEditContractModalData(item, false, true)));
    }
  };

  const handleDelete = () => {
    if (item) {
      dispatch(openBackofficeModal(new DeleteContractModalData(item.id, true)));
    }
  };

  const options: IMenuOption[] = [
    {
      title: <FormattedMessage id="label.edit" />,
      dataTestId: 'single-contract-more-button-edit-option',
      icon: <NotePencil />,
      onClick: handleEdit,
      visible: true,
    },
    {
      title: <FormattedMessage id="label.delete" />,
      dataTestId: 'single-contract-more-button-delete-option',
      textColor: COLOURS.Strawberry.Solid[600],
      icon: <Trash color={COLOURS.Strawberry.Solid[600]} />,
      onClick: handleDelete,
      visible: true,
    },
  ];

  return <MoreButton options={options} variant="square" padding={4} dataTestId="single-contract-header-more-button" />;
};

export default SingleContractHeaderMoreButton;
