import filters, { IContractsFiltersState } from '@/pages/backoffice/Contracts/ContractsFilters/reducer';
import list, { IContractsListState } from '@/pages/backoffice/Contracts/ContractsList/reducer';
import singleContract, { ISingleContractState } from '@/pages/backoffice/Contracts/SingleContractRoot/reducer';
import singleContractTransactions, {
  ISingleContractTransactions,
} from '@/pages/backoffice/Contracts/SingleContractTransactions/reducer';
import { combineReducers } from 'redux';

export interface IContractsState {
  filters: IContractsFiltersState;
  list: IContractsListState;
  singleContract: ISingleContractState;
  singleContractTransactions: ISingleContractTransactions;
}

export default combineReducers({
  filters,
  list,
  singleContract,
  singleContractTransactions,
});
