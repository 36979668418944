import { COLOURS } from '@core-ui/styles';
import { HeaderCell, TableRow } from '@core-ui/table';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import SvgIcon from '@mui/material/SvgIcon';
import TableHead from '@mui/material/TableHead';
import { useAppSelector } from '@/hooks/useAppSelector';
import {
  getSingleContractTransactions,
  setAllCheckedItems,
  setOrderBy,
  setSortDesc,
} from '@/pages/backoffice/Contracts/SingleContractTransactions/SingleContractTransactionsList/actions';
import { getSortOrderChecked } from '@/pages/backoffice/Contracts/SingleContractTransactions/SingleContractTransactionsList/selector';
import { ArrowDown, ArrowsDownUp, ArrowUp } from '@phosphor-icons/react';
import React, { FC } from 'react';
import { batch, useDispatch } from 'react-redux';
import { HEADERS } from './consts';
import useStyles from './styles';

const TableHeader: FC = () => {
  const { orderBy, isSortDesc, indeterminate, checked, isAllItemsDisabled } = useAppSelector(getSortOrderChecked);

  const styles = useStyles();
  const dispatch = useDispatch();

  const handleChangeSort = (value?: string) => () => {
    if (!value) {
      return;
    }

    batch(() => {
      if (orderBy !== value) {
        dispatch(setSortDesc(true));
      } else {
        dispatch(setSortDesc(!isSortDesc));
      }

      dispatch(setOrderBy(value));
      dispatch(getSingleContractTransactions(null));
    });
  };

  const handleToggleAllChecked = () => {
    if (indeterminate || !checked) {
      dispatch(setAllCheckedItems(true));
    }

    if (checked && !indeterminate) {
      dispatch(setAllCheckedItems(false));
    }
  };

  return (
    <TableHead sx={styles.head}>
      <TableRow>
        {/* TODO: реализация чекбоксов для bulk actions отложена */}
        {/* <HeaderCell */}
        {/*   sticky */}
        {/*   sx={{ */}
        {/*     ...styles.checkboxCell, */}
        {/*     cursor: isAllItemsDisabled ? 'default' : 'pointer', */}
        {/*   }} */}
        {/*   onClick={handleToggleAllChecked} */}
        {/* > */}
        {/*  <Checkbox checked={checked} indeterminate={indeterminate} size="large" disabled={isAllItemsDisabled} /> */}
        {/* </HeaderCell> */}

        {HEADERS.map((column) => (
          <HeaderCell
            key={column.key}
            align={column.align}
            onClick={handleChangeSort(column.orderBy)}
            right={column.right}
            shadow={column.shadow}
            sticky
            sx={{
              cursor: column.orderBy ? 'pointer' : 'initial',
              minWidth: column.width,
              width: column.width,
              maxWidth: column.width,
            }}
          >
            <Grid
              container
              alignItems="center"
              gap={1}
              wrap="nowrap"
              justifyContent={
                column.align === 'right' ? 'flex-end' : column.align === 'center' ? 'center' : 'space-between'
              }
            >
              <Box>{column.title}</Box>

              {column.orderBy && (
                <SvgIcon fontSize="small">
                  {column.orderBy === orderBy && isSortDesc && <ArrowDown />}
                  {column.orderBy === orderBy && !isSortDesc && <ArrowUp />}
                  {column.orderBy !== orderBy && <ArrowsDownUp color={COLOURS.Coconut.Solid[500]} />}
                </SvgIcon>
              )}
            </Grid>
          </HeaderCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

export default TableHeader;
