import { getChunkList, getList, resetState, setChunkList, setList, setLoading } from '@core-ui/reducers_handlers';
import { InboxRecordSchema } from '@/generated';
import { ICheckedItems } from '@/pages/backoffice/Contracts/types';
import { createAction } from 'redux-actions';

export const reducerName = 'contracts.single_contract.list';

export const getSingleContractTransactions = getList(reducerName);
export const setSingleContractTransactions = setList<InboxRecordSchema>(reducerName);

export const getChunkSingleContractTransactions = getChunkList(reducerName);
export const setChunkSingleContractTransactions = setChunkList<InboxRecordSchema>(reducerName);

export const setOrderBy = createAction<string>(`${reducerName}.SET_ORDER_BY`);
export const setSortDesc = createAction<boolean>(`${reducerName}.SET_SORT_DESC`);

export const toggleCheckedItem = createAction<number>(`${reducerName}.TOGGLE_CHECKED_ITEM`);
export const setCheckedItems = createAction<ICheckedItems>(`${reducerName}.SET_CHECKED_ITEMS`);
export const setAllCheckedItems = createAction<boolean>(`${reducerName}.SET_ALL_CHECKED_ITEMS`);

export const setPaginationPage = createAction<number>(`${reducerName}.SET_PAGINATION_PAGE`);

export const setSingleContractTransactionsListLoading = setLoading(reducerName);

export const resetSingleContractTransactionsList = resetState(reducerName);
