import { createAction } from 'redux-actions';

export const reducerName = 'inbox.drag_n_drop';

export interface IUploadFiles {
  files: File[];
}

export const uploadFiles = createAction<IUploadFiles>(`${reducerName}.UPLOAD_DOCUMENT`);

export const setLoading = createAction<boolean>(`${reducerName}.SET_LOADING`);

export const setPlaceholders = createAction<string[]>(`${reducerName}.SET_PLACEHOLDERS`);
