import { DELETE } from '@/api/oceanApi';
import { responseError } from '@/app/sagas';
import { exhaustiveGuard } from '@/app/utils/app';
import { showNotification } from '@/components/Notification/actions';
import { FileStatusEnum } from '@/generated';
import { getInboxList } from '@/pages/backoffice/Inbox/InboxList/actions';
import { closeBackofficeModal, toggleModalLoading } from '@/pages/backoffice/Modals/actions';
import { Action } from 'redux-actions';
import { all, call, put, takeLatest } from 'redux-saga/effects';
import * as actions from './actions';

function* deleteInboxDocument({ payload }: Action<actions.IDeleteInboxDocument>) {
  try {
    yield put(toggleModalLoading());

    // TODO: we are not sure about UNKNOWN status, need to check which endpoint should be used for this type of file
    switch (payload.status) {
      case FileStatusEnum.DIGITIZED:
      case FileStatusEnum.UNKNOWN:
        yield call(DELETE, `/document/${payload.id}`);
        break;
      case FileStatusEnum.RETURNED:
      case FileStatusEnum.BEING_DIGITIZED:
        yield call(DELETE, `/files/${payload.id}`);
        break;
      default:
        exhaustiveGuard(payload.status);
    }

    yield all([
      put(getInboxList(null)),
      put(closeBackofficeModal()),
      put(
        showNotification({
          variant: 'success',
          titleId: 'notification.success.text.inbox_document_delete',
        })
      ),
    ]);
  } catch (e) {
    yield all([call(responseError, e), put(toggleModalLoading())]);
  }
}

export default [takeLatest(actions.deleteInboxDocument, deleteInboxDocument)];
