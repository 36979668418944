import { COLOURS } from '@core-ui/styles';
import { IMenuOption } from '@/components/Menu/Menu';
import MoreButton from '@/components/MoreButton';
import { FileStatusEnum, InboxRecordSchema } from '@/generated';
import { openBackofficeModal } from '@/pages/backoffice/Modals/actions';
import { DeleteInboxDocumentData } from '@/pages/backoffice/Modals/command';
import { DownloadSimple, Trash } from '@phosphor-icons/react';
import React, { FC } from 'react';
import { FormattedMessage } from 'components/Intl';
import { useDispatch } from 'react-redux';

export interface IProps {
  file: InboxRecordSchema;
}

const InboxRowMoreButton: FC<IProps> = (props) => {
  const { file } = props;

  const dispatch = useDispatch();

  const handleDownload = () => {
    window.open(file.link);
  };

  const handleDelete = () => {
    // TODO: we are not sure about UNKNOWN status, need to check which endpoint should be used for this type of file

    // only already digitized files have document_id. We use /files/{file_id} route for deleting
    // not yet digitized files and /document/{document_id} route for deleting already digitized
    const id = file.status === (FileStatusEnum.DIGITIZED || FileStatusEnum.UNKNOWN) ? file.document_id : file.id;

    if (id) {
      dispatch(openBackofficeModal(new DeleteInboxDocumentData(id, file.status)));
    }
  };

  // TODO: добавить go to ledger, но её бизнес логика пока не продумана
  const options: IMenuOption[] = [
    {
      title: <FormattedMessage id="label.download" />,
      icon: <DownloadSimple />,
      onClick: handleDownload,
      visible: true,
    },
    {
      title: <FormattedMessage id="label.delete" />,
      dataTestId: 'inbox-more-button-delete',
      textColor: COLOURS.Strawberry.Solid[600],
      icon: <Trash color={COLOURS.Strawberry.Solid[600]} />,
      onClick: handleDelete,
      visible: true,
    },
  ];

  return <MoreButton options={options} dataTestId="inbox-more-button" />;
};

export default InboxRowMoreButton;
