import { POST } from '@/api/oceanApi';
import { responseError } from '@/app/sagas';
import { vehicleIdSelector } from '@/app/selectors';
import { showNotification } from '@/components/Notification/actions';
import { getInboxList } from '@/pages/backoffice/Inbox/InboxList/actions';
import { Action } from 'redux-actions';
import { all, call, put, select, takeEvery } from 'redux-saga/effects';
import * as actions from './actions';

const createFormData = (payload: actions.IUploadFiles) => {
  const formData = new FormData();
  payload.files.forEach((file) => formData.append('fileobjects', file));

  return formData;
};

function* uploadDocument({ payload }: Action<actions.IUploadFiles>) {
  try {
    const boatId: number = yield select(vehicleIdSelector);
    const fileNames = payload.files.map((file) => file.name);

    yield all([put(actions.setLoading(true)), put(actions.setPlaceholders(fileNames))]);

    const formData = createFormData(payload);
    yield call(POST, `/inbox/${boatId}/files`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });

    yield all([
      put(getInboxList(null)),
      put(
        showNotification({
          variant: 'success',
          titleId: 'notification.success.text.document_uploaded',
          message: '',
        })
      ),
    ]);
  } catch (e) {
    yield call(responseError, e);
  } finally {
    yield all([put(actions.setLoading(false)), put(actions.setPlaceholders([]))]);
  }
}

export default [takeEvery(actions.uploadFiles, uploadDocument)];
