import State from '@/app/types/state';
import { NoDataContainerSelector } from '@/components/NoDataContainer/NoDataContainer';
import { createSelector } from 'reselect';

export const getSingleContract = (state: State) => state.backoffice.contracts.singleContract;

export const getNoDataContainerData: NoDataContainerSelector = createSelector([getSingleContract], (state) => ({
  loading: state.loading,
  loaded: state.loaded,
  hasData: state.hasData,
  error: state.error,
}));
