import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import DragAndDrop from '@/pages/backoffice/Inbox/DragAndDrop/DragAndDrop';
import InboxFilters from '@/pages/backoffice/Inbox/InboxFilters';
import InboxList from '@/pages/backoffice/Inbox/InboxList';
import React from 'react';
import useStyles from './styles';

const InboxRoot = () => {
  const styles = useStyles();

  return (
    <Container maxWidth="xl" sx={styles.container}>
      <Grid item container sx={styles.innerContainer}>
        <DragAndDrop />

        <InboxFilters />
        <InboxList />
      </Grid>
    </Container>
  );
};

export default InboxRoot;
