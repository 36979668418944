import Container from '@mui/material/Container';
import { SxProps, Theme } from '@mui/material/styles';
import {
  resetSingleContractTransactionsFilters,
  setSingleContractTransactionsFiltersFromQuery,
  updateSingleContractTransactionsFiltersQuery,
} from '@/pages/backoffice/Contracts/SingleContractTransactions/SingleContractTransactionsFilters/actions';
import {
  getSingleContractTransactions,
  resetSingleContractTransactionsList,
} from '@/pages/backoffice/Contracts/SingleContractTransactions/SingleContractTransactionsList/actions';
import React, { FC, useEffect } from 'react';
import { batch, useDispatch } from 'react-redux';
import SingleContractTransactionsDragNDrop from './SingleContractTransactionsDragNDrop';
import SingleContractTransactionsFilters from './SingleContractTransactionsFilters';
import SingleContractTransactionsList from './SingleContractTransactionsList';

const containerStyle: SxProps<Theme> = {
  padding: '0 !important',
  height: '100%',
  overflow: 'hidden',
};

const SingleContractTransactions: FC = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    batch(() => {
      dispatch(resetSingleContractTransactionsList());
      dispatch(resetSingleContractTransactionsFilters());
      dispatch(setSingleContractTransactionsFiltersFromQuery());
      dispatch(updateSingleContractTransactionsFiltersQuery());
      dispatch(getSingleContractTransactions(null));
    });
  }, []);

  return (
    <Container maxWidth="xl" sx={containerStyle}>
      <SingleContractTransactionsDragNDrop />

      <SingleContractTransactionsFilters />

      <SingleContractTransactionsList />
    </Container>
  );
};

export default SingleContractTransactions;
