import dragNDrop, { IInboxDragNDropState } from '@/pages/backoffice/Inbox/DragAndDrop/reducer';
import list, { IInboxList } from '@/pages/backoffice/Inbox/InboxList/reducer';
import filters, { IInboxFiltersState } from '@/pages/backoffice/Inbox/InboxFilters/reducer';
import { combineReducers } from 'redux';

export interface IInboxState {
  filters: IInboxFiltersState;
  list: IInboxList;
  dragNDrop: IInboxDragNDropState;
}

export default combineReducers({
  filters,
  list,
  dragNDrop,
});
