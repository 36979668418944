import Grid from '@mui/material/Grid';
import Skeleton from '@mui/material/Skeleton';
import { generateEmptyArray } from '@/app/utils/app';
import React, { FC } from 'react';

const SingleContractInfoSkeleton: FC = () => {
  return (
    <Grid container direction="column" gap={4} maxWidth={350}>
      {generateEmptyArray(9).map((_, index) => (
        <Skeleton key={index} variant="rounded" height={20} />
      ))}
    </Grid>
  );
};

export default SingleContractInfoSkeleton;
