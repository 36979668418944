import { buildURL } from '@core-ui/url';
import { vehicleIdSelector } from '@/app/selectors';
import React from 'react';
import { useParams } from 'react-router';
import { FormattedMessage } from 'components/Intl';
import Tabs from '@core-ui/tabs';
import { IRouteParams, ROUTES } from '@/app/consts/routes';
import Grid from '@mui/material/Grid';
import { BREAKPOINTS } from '@core-ui/styles';
import Paper from '@mui/material/Paper';
import { BACKOFFICE_TABS } from '@/pages/backoffice/consts';
import { useAppSelector } from '@/hooks/useAppSelector';
import useStyles from './styles';

interface IProps {}

const BackofficeTabs: React.FC<IProps> = () => {
  const styles = useStyles();
  const boatId = useAppSelector(vehicleIdSelector);
  const params = useParams<IRouteParams>();

  const tabs = [
    {
      value: BACKOFFICE_TABS.INBOX,
      label: <FormattedMessage id="backoffice.tabs.inbox" />,
      dataTestId: 'inbox-tab',
      to: buildURL(`/${boatId}/${ROUTES.BACKOFFICE}/${BACKOFFICE_TABS.INBOX}`),
    },
    {
      value: BACKOFFICE_TABS.WALLETS,
      label: <FormattedMessage id="backoffice.tabs.wallets" />,
      dataTestId: 'wallets-tab',
      to: buildURL(`/${boatId}/${ROUTES.BACKOFFICE}/${BACKOFFICE_TABS.WALLETS}`),
    },
    {
      value: BACKOFFICE_TABS.CONTRACTS,
      label: <FormattedMessage id="backoffice.tabs.contracts" />,
      dataTestId: 'contracts-tab',
      to: buildURL(`/${boatId}/${ROUTES.BACKOFFICE}/${BACKOFFICE_TABS.CONTRACTS}`),
    },
    {
      value: BACKOFFICE_TABS.REQUESTS,
      label: <FormattedMessage id="backoffice.tabs.requests" />,
      dataTestId: 'requests-tab',
      to: buildURL(`/${boatId}/${ROUTES.BACKOFFICE}/${BACKOFFICE_TABS.REQUESTS}`),
    },
    {
      value: BACKOFFICE_TABS.LEDGER,
      label: <FormattedMessage id="backoffice.tabs.ledger" />,
      dataTestId: 'ledger-tab',
      to: buildURL(`/${boatId}/${ROUTES.BACKOFFICE}/${BACKOFFICE_TABS.LEDGER}`),
    },
  ];

  return (
    <Paper elevation={0} sx={styles.paper}>
      <Grid item marginTop="auto" padding="0 24px" width="100%" maxWidth={BREAKPOINTS.XL}>
        <Tabs size="small" tabs={tabs} active={params.backofficeTab} />
      </Grid>
    </Paper>
  );
};

export default BackofficeTabs;
