import Loader from '@core-ui/loader';
import { TableCell, TableRow } from '@core-ui/table';
import Grid from '@mui/material/Grid';
import React, { FC } from 'react';
import { FormattedMessage } from 'components/Intl';

interface IProps {
  item: string;
}

const InboxPlaceholderRow: FC<IProps> = (props) => {
  const { item } = props;

  return (
    <TableRow>
      <TableCell colSpan={8}>
        <Grid container justifyContent="flex-start" alignItems="center">
          <Grid item marginRight={2}>
            <Loader mode="circle" padding={0} size={15} />
          </Grid>

          <Grid item>
            {item}
            <FormattedMessage id="label.is_uploading" />
          </Grid>
        </Grid>
      </TableCell>
    </TableRow>
  );
};

export default InboxPlaceholderRow;
