import Immutable, { asMutable } from '@core-ui/immutable';
import reducer, { List } from '@core-ui/reducers_handlers';
import { Nullable } from '@core-ui/types';
import { InboxRecordSchema, OrderContractContentFields } from '@/generated';
import { ICheckedItem, ICheckedItems } from '@/pages/backoffice/Contracts/types';
import { Action, handleActions } from 'redux-actions';
import * as actions from './actions';

// actual request response schema is ContractContentSchema
export interface ISingleContractTransactionsListState extends List<InboxRecordSchema> {
  checkedItems: ICheckedItems;
  orderBy: OrderContractContentFields;
  isSortDesc: Nullable<boolean>;
  paginationPage: number;
}

const defaultState = Immutable<ISingleContractTransactionsListState>({
  loaded: false,
  hasData: false,
  loading: false,
  chunkLoading: false,
  isAllDataReceived: false,
  checkedItems: {},
  orderBy: OrderContractContentFields.CREATED_AT,
  isSortDesc: true,
  paginationPage: 0,
});

export default handleActions<typeof defaultState, any>(
  {
    [actions.toggleCheckedItem.toString()]: (state, { payload }: Action<number>) => {
      return state.setIn(['checkedItems', payload, 'checked'], !state.checkedItems[payload].checked);
    },
    [actions.setCheckedItems.toString()]: (state, { payload }: Action<ICheckedItems>) => {
      return state.set('checkedItems', payload);
    },
    [actions.setAllCheckedItems.toString()]: (state, { payload }: Action<boolean>) => {
      const newCheckedItemsState: ICheckedItems = asMutable(state.checkedItems);

      Object.entries<ICheckedItem>(state.checkedItems.asMutable()).forEach(([key, value]) => {
        newCheckedItemsState[Number(key)] = {
          documentId: Number(value.documentId),
          checked: payload,
        };
      });

      return state.merge({ checkedItems: newCheckedItemsState });
    },
    [actions.setOrderBy.toString()]: (state, { payload }: Action<string>) => {
      return state.set('orderBy', payload);
    },
    [actions.setSortDesc.toString()]: (state, { payload }: Action<boolean>) => {
      return state.set('isSortDesc', payload);
    },
    [actions.setPaginationPage.toString()]: (state, { payload }: Action<number>) => {
      return state.set('paginationPage', payload);
    },
  },
  reducer<typeof defaultState>(actions.reducerName, defaultState)
);
