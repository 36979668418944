import Autocomplete from '@core-ui/autocomplete';
import { useFilter } from '@core-ui/hooks';
import TextField from '@core-ui/text_field';
import { Nullable } from '@core-ui/types';
import Grid from '@mui/material/Grid';
import SvgIcon from '@mui/material/SvgIcon';
import { DATE_FORMAT, RANGE_PICKER_LABELS } from '@/app/consts/app';
import { dateToDayEndUTC, dateToDayStartUTC, dateToUTC } from '@/app/utils/dates';
import DatePicker, { RangePickerProps } from '@core-ui/datepicker';
import { useAppSelector } from '@/hooks/useAppSelector';
import { TRANSACTIONS_STATUS_OPTIONS } from '@/pages/backoffice/Contracts/consts';
import { setTextFilter } from '@/pages/backoffice/Contracts/SingleContractTransactions/SingleContractTransactionsFilters/actions';
import { ISingleContractListFiltersState } from '@/pages/backoffice/Contracts/SingleContractTransactions/SingleContractTransactionsFilters/reducer';
import {
  getSingleContractTransactions,
  setSingleContractTransactionsListLoading,
} from '@/pages/backoffice/Contracts/SingleContractTransactions/SingleContractTransactionsList/actions';
import { MagnifyingGlass } from '@phosphor-icons/react';
import React, { FC, useCallback } from 'react';
import { FormattedMessage } from 'components/Intl';
import { batch, useDispatch } from 'react-redux';
import selector from './selector';

const SingleContractTransactionsFilters: FC = () => {
  const { filters, uploadedByOptions } = useAppSelector(selector);

  const dispatch = useDispatch();

  const setLoadingCallback = useCallback(
    () => dispatch(setSingleContractTransactionsListLoading({ loading: true })),
    [setSingleContractTransactionsListLoading]
  );

  const handleFilterChange = useFilter<ISingleContractListFiltersState>({
    getList: getSingleContractTransactions,
    setFilter: setTextFilter,
    onChange: setLoadingCallback,
  });

  const handleDateChange: RangePickerProps['onChange'] = (dates) => {
    batch(() => {
      handleFilterChange('dateFrom')(dates?.[0] ? dateToDayStartUTC(dates[0]) : null);
      handleFilterChange('dateTo')(dates?.[1] ? dateToDayEndUTC(dates[1]) : null);
    });
  };

  const pickerValue: [Nullable<Date>, Nullable<Date>] = [null, null];

  if (filters.dateFrom) {
    pickerValue[0] = dateToUTC(Number(filters.dateFrom));
  }

  if (filters.dateTo) {
    pickerValue[1] = dateToUTC(Number(filters.dateTo));
  }

  return (
    <Grid container wrap="nowrap" padding="24px 0" gap={3}>
      <Grid item width={202}>
        <TextField
          fullWidth
          size="medium"
          label={<FormattedMessage id="filters.search" />}
          // TODO: тип параметра onChange в компоненте TextField не соответствует handleFilterChange
          // @ts-ignore
          onChange={handleFilterChange('search')}
          value={filters.search}
          rightIcon1={
            <SvgIcon>
              <MagnifyingGlass />
            </SvgIcon>
          }
        />
      </Grid>

      <Grid item width={276}>
        <DatePicker<RangePickerProps>
          dateTimeFormat={DATE_FORMAT}
          value={pickerValue}
          onChange={handleDateChange}
          data-testid="single-contract-filter-rangepicker"
          label={RANGE_PICKER_LABELS}
          size="medium"
          view="rangepicker"
          openOnFocus
        />
      </Grid>

      <Grid item width={308}>
        <Autocomplete
          limitTags={1}
          options={uploadedByOptions}
          value={filters.uploadedBy}
          fullWidth
          label={<FormattedMessage id="label.uploaded_by" />}
          onChange={handleFilterChange('uploadedBy')}
          multiple
        />
      </Grid>

      <Grid item width={308}>
        <Autocomplete
          limitTags={2}
          options={TRANSACTIONS_STATUS_OPTIONS}
          value={filters.status}
          fullWidth
          label={<FormattedMessage id="label.status" />}
          onChange={handleFilterChange('status')}
          multiple
        />
      </Grid>

      {/* todo: тут будет кнопка send to erp (bulk) */}
    </Grid>
  );
};

export default SingleContractTransactionsFilters;
