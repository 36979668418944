import { getChunkList, getList, resetState, setChunkList, setList, setLoading } from '@core-ui/reducers_handlers';
import { SORT_ORDER_ENUM } from '@core-ui/types';
import { InboxRecordSchema } from '@/generated';
import { createAction } from 'redux-actions';

export const reducerName = 'inbox.list';

export const getInboxList = getList(reducerName);
export const setInboxList = setList<InboxRecordSchema>(reducerName);

export const getChunkInboxList = getChunkList(reducerName);
export const setChunkInboxList = setChunkList<InboxRecordSchema>(reducerName);

export const resetInboxList = resetState(reducerName);

export const setOrderBy = createAction<string>(`${reducerName}.SET_ORDER_BY`);
export const setOrder = createAction<SORT_ORDER_ENUM>(`${reducerName}.SET_ORDER`);

export const setPaginationPage = createAction<number>(`${reducerName}.SET_PAGINATION_PAGE`);

export const setInboxListLoading = setLoading(reducerName);
