import { DEBOUNCE_PERIOD } from '@core-ui/consts';
import { updateQuery } from '@core-ui/url';
import { ISagaContext } from '@/app/types/common';
import { ISingleContractListFiltersState } from '@/pages/backoffice/Contracts/SingleContractTransactions/SingleContractTransactionsFilters/reducer';
import { getSingleContractTransactionsFilters } from '@/pages/backoffice/Contracts/SingleContractTransactions/SingleContractTransactionsFilters/selector';
import { debounce, getContext, select, takeLatest } from 'redux-saga/effects';
import * as actions from './actions';

function* filterToQuerySaga() {
  const filters: ISingleContractListFiltersState = yield select(getSingleContractTransactionsFilters);
  const history: ISagaContext['history'] = yield getContext('history');

  history.replace(updateQuery(filters));
}

export default [
  debounce(DEBOUNCE_PERIOD, actions.setTextFilter, filterToQuerySaga),
  takeLatest(actions.updateSingleContractTransactionsFiltersQuery, filterToQuerySaga),
];
