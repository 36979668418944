import { ScrollMemory } from '@core-ui/redux-router';
import Grid from '@mui/material/Grid';
import { SxProps, Theme } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import ChunkLoader from '@/components/ChunkLoader';
import NoDataContainer from '@/components/NoDataContainer';
import NoDataPlaceholder from '@/components/NoDataPlaceholder';
import NoFiltratedDataPlaceholder from '@/components/NoFiltratedDataPlaceholder';
import TableSkeleton from '@/components/TableSkeleton';
import { useAppSelector } from '@/hooks/useAppSelector';
import {
  resetContractsFilters,
  updateContractsFiltersQuery,
} from '@/pages/backoffice/Contracts/ContractsFilters/actions';
import { getChunkContractsList, getContractsList } from '@/pages/backoffice/Contracts/ContractsList/actions';
import ContractsListRow from '@/pages/backoffice/Contracts/ContractsList/ContractsListRow';
import TableHeader from '@/pages/backoffice/Contracts/ContractsList/TableHeader';
import React, { FC } from 'react';
import { batch, useDispatch } from 'react-redux';
import selector, { chunkLoaderSelector, getNoDataContainerData } from './selector';

const CONTAINER_ID = 'contracts-list';

const noDataContainerStyle: SxProps<Theme> = {
  height: '100%',
  overflow: 'scroll',
};

const ContractsList: FC = () => {
  const { items, amountFormatter, getCurrencyCode, isFiltersApplied } = useAppSelector(selector);
  const dispatch = useDispatch();

  const handleClearFilters = () => {
    batch(() => {
      dispatch(resetContractsFilters());
      dispatch(updateContractsFiltersQuery());
      dispatch(getContractsList(null));
    });
  };

  const noDataContent = isFiltersApplied ? (
    <NoFiltratedDataPlaceholder clearFilters={handleClearFilters} />
  ) : (
    <NoDataPlaceholder />
  );

  return (
    <>
      <ScrollMemory elementID={CONTAINER_ID} />

      <NoDataContainer
        sx={noDataContainerStyle}
        selector={getNoDataContainerData}
        loader={<TableSkeleton rows={10} />}
        noDataContent={noDataContent}
        id={CONTAINER_ID}
      >
        <Grid item width="100%" height="100%">
          <Table stickyHeader>
            <TableHeader />

            <TableBody>
              {items?.map((item) => (
                <ContractsListRow
                  key={item.id}
                  item={item}
                  amountFormatter={amountFormatter}
                  getCurrencyCode={getCurrencyCode}
                />
              ))}
            </TableBody>
          </Table>

          <ChunkLoader selector={chunkLoaderSelector} getChunk={getChunkContractsList} />
        </Grid>
      </NoDataContainer>
    </>
  );
};

export default ContractsList;
